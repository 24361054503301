import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { IconButtonComponent } from './icon-button.component';

@NgModule({
  declarations: [IconButtonComponent],
  imports: [CommonModule, MatIconModule],
  exports: [IconButtonComponent],
})
export class IconButtonModule {}
