import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'filterForm',
  pure: false
})
export class FilterFormPipe implements PipeTransform {

  transform(values: any[], filterForm: FormGroup, properties: any[]): any[] {
    if(!values || !values.length) {
      return [];
    }
    if(!filterForm) {
      return values;
    }
    return values.filter((value: any) => {
      let foundValue = true;
      for (const property of properties) {
        if(!filterForm.get(property.formKey)?.value) {
          continue;
        }
        const formValue = filterForm.get(property.formKey)?.value;
        if (property.queryType === 'array') {
          foundValue = formValue.some(x => x === value[property.valueKey]);
          if(foundValue) {
            continue;
          }
          break;
        }
        if(property.queryType === 'like') {
          foundValue = value[property.valueKey].toLowerCase().includes(formValue.toLowerCase());
          if(foundValue) {
            continue;
          }
          break;
        }
        foundValue =  value[property.valueKey] === filterForm.get(property.formKey)?.value
      }
      return foundValue;
    })
  }

}
