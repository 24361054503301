import { Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';

export type InputType = 'text' | 'number' | 'password';

@Component({
  selector: 'custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent {
  @Input()
  public label: string;
  @Input()
  public maxlength: number;
  @Input()
  public placeholder: string;
  @Input()
  public hintLabel: string;
  @Input()
  public textPrefix: string;
  @Input()
  public textSuffix: string;
  @Input()
  public type: InputType;
  @Input()
  public appearance: MatFormFieldAppearance;
  @Input()
  public customErrors: any[];
  @Input()
  public floatLabel: FloatLabelType;
  public isVisiblePassword: boolean;
  private noopValueAccessor: ControlValueAccessor;

  constructor(
    @Optional() public ngControl: NgControl,
  ) {
    this.initNoopValueAccessor();
    this.appearance = 'fill';
    this.type = 'text';
    this.isVisiblePassword = false;
    this.floatLabel = 'auto';
    if (this.ngControl) {
      this.ngControl.valueAccessor = this.noopValueAccessor;
    }
    this.customErrors = [];
  }

  private initNoopValueAccessor(): void {
    this.noopValueAccessor = {
      writeValue(): void {
      },
      registerOnChange(): void {
      },
      registerOnTouched(): void {
      }
    }
  }

}
