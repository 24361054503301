<div *ngIf="env != 'production'" class="text-center font-bold"
    [ngStyle]="{ 'background-color': env == 'development' ? 'red': env == 'staging' ? 'yellow': '' }">--- {{env | uppercase }} ---</div>
<div class="bg-white flex justify-between py-3 px-5 items-center shadow-md">
    <div class="h-4 p-1" style="height: 30px">
      <a style="position: absolute">
        <img style="height: 24px" src="../../../assets/logo-white.png" alt="logo">
      </a>
    </div>
    <div class="flex pl-11"></div>
    <div class="flex items-center">
        <div class="mr-6 hover:underline flex items-center cursor-pointer">
            <mat-icon class="h-6 w-6 text-gray-800" (click)="navToPlatform()">language</mat-icon>
        </div>
        <div class="mr-6 hover:underline flex items-center cursor-pointer">
            <mat-icon class="h-6 w-6" svgIcon="es" (click)="changeLanguage()"
                [ngClass]="spanish ? [''] : ['opacity-40']"></mat-icon>
        </div>

        <div class="mr-6 hover:underline flex items-center cursor-pointer">
            <mat-icon class="h-6 w-6" svgIcon="en" (click)="changeLanguage()"
                [ngClass]="spanish ? ['opacity-40'] : ['']"></mat-icon>
        </div>
        <div class="mr-6 hover:underline flex items-center cursor-pointer" *ngIf="user" (click)="openConfirmDialog()">
            <mat-icon class="h-6 w-6 text-gray-800">power_settings_new</mat-icon>
        </div>
        <div class="flex items-center bg-gray-200 p-1 rounded-full " *ngIf="user && user.firstName">
            <div class="flex items-center">
                <img [src]="'https://ui-avatars.com/api/?name=' + getInitials() + '&background=F3B13E&color=fff'"
                    class="w-6 h-6 rounded-full ">
                <h1 class="text-sm text-gray-800 mx-2">{{user.displayName}}</h1>
            </div>
        </div>
    </div>

</div>
