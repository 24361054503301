import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'forIn'
})
export class ForInPipe implements PipeTransform {

  transform(value: any): string[] {
    const keys: string[] = [];
    if(!value){
      return keys;
    }
    for (const valueKey in value) {
      keys.push(valueKey);
    }
    return keys;
  }

}
