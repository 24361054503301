import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timerFormat',
})
export class TimerFormatPipe implements PipeTransform {

  constructor() {}

  transform(seconds: number): string {
    return new Date(seconds * 1000).toISOString().substring(14, 19);
  }

}
