<form action="" class="mt-1" [formGroup]="loginForm" (ngSubmit)="login()">
  <div class="flex justify-center items-center h-full pt-32">
    <div class="w-[500px] px-20 py-10 shadow-2xl bg-white rounded-xl">
      <div class="flex justify-center items-center pb-8">
        <img class="justify-end items-end" src="../../../assets/logo-white.png" class="justify-end items-end">
      </div>
      <mat-form-field class="mt-3  w-full opacity-60">
        <mat-label translate>User</mat-label>
        <input matInput placeholder="Email" formControlName="username">
      </mat-form-field>

      <mat-form-field class="mt-3 w-full opacity-60">
        <mat-label translate>Password</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center">
          <mat-icon (click)="hide = !hide" class="cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </div>
      </mat-form-field>

      <div class="mt-5" [ngClass]="isValid() ? [''] : ['opacity-60']">
        <text-button name="Log In" styles="bg-yellow-900 w-full font-bold text-lg hover:opacity-90">
        </text-button>
      </div>

      <div class="mt-3 items-center pt-6">
        <p class="cursor-pointer text-center font-sans text-lg font-light opacity-80" translate>
          Don't have an account? <strong translate class="font-medium text-lg font-sans opacity-90"
            (click)="navToPlatform()" translate>Request a demo</strong>
        </p>
        <p class="cursor-pointer underline mt-3 text-center" translate routerLink="/recovery-password">
          Have you forgotten your password?
        </p>
      </div>
    </div>
  </div>
</form>