import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'range'
})
export class RangePipe implements PipeTransform {

  transform(value: number): any[] {
    if (!value) {
      return [];
    }
    const result = [];
    for (let i = 1; i <= value; i++) {
      result.push(i);
    }
    return result;
  }

}
