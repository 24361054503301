<mat-drawer-container class="absolute top-0 bottom-0 left-0 right-0" autosize>
    <top-navbar  id="topNavbar"></top-navbar>
    <mat-drawer *ngIf="this.authService.isLoggedUser" #drawer mode="side" disableClose="true" opened="true">
        <side-nav (onToggleSideNav)="onToggleSideNav($event)"></side-nav>
    </mat-drawer>

    <div class="body w-full flex flex-col bg-gray-100 pl-16">
        <div class="message-overlay" *ngIf="!isLargeScreen()">
            <p class="text-center">{{'The Dashboard website is currently designed for use in larger screens.' | translate}}
            </p>
        </div>
        <ng-container *ngIf="isLargeScreen()"><router-outlet></router-outlet></ng-container>
    </div>
</mat-drawer-container>
