import moment from 'moment-timezone';

export class GenericFunctions {
  public static getNumberWithSign(number: Number): string {
    if (number === 0) {
      return "0"
    }
    const sign = number < 0 ? '-' : '+';
    return `${sign}${number}`;
  }

  public static getTimeZone(location = 'Europa/Madrid'): string {
    return moment.tz(location).format('Z');
  }

  public static isNullOrUndefined(value: string | number): boolean {
    return value === null || value === undefined;
  }
}
