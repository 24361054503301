import { Pipe, PipeTransform } from '@angular/core';
import { RoleType } from '../../utils/custom-types';

@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {
  transform(roles: RoleType[] | string[]): boolean {
    const user = localStorage.getItem('userInfo');
    if(!roles || !user) {
      return false;
    }
    const jsonUser = JSON.parse(user);
    return roles.some(role => role === jsonUser.role);
  }

}
