
<div (mouseleave)="this.showFullMenu = false" class="h-full px-2 bg-white flex flex-col" [ngClass]="!this.fixedFullMenu ? 'fixed': '' ">
  <div class="h-4 p-1 mt-6" style="height: 50px">
    <a style="position: absolute" [@openClose]="(this.showFullMenu || this.fixedFullMenu) ? 'open' : 'closed'">
      <img height="34" src="../../../assets/logo-white.png" alt="logo">
    </a>
  </div>
  <ul>
    <li class="hover:bg-gray-100 flex rounded-md p-2 text-slate-700 text-sm my-2 cursor-pointer items-center"
        routerLinkActive="bg-gray-100" *ngFor="let item of menuItems; index as i;" [routerLink]="item.route">
      <div class="flex items-center">
        <mat-icon class="text-yellow-900 opacity-60 h-6 w-6" matTooltipPosition="right"
                  [matTooltip]="item.title | translate">{{item.icon}}</mat-icon>
        <span [@openClose]="(this.showFullMenu || this.fixedFullMenu) ? 'open' : 'closed'" class="text-base text-gray-800 pl-1 overflow-hidden whitespace-nowrap text-ellipsis">
          {{item.title}}
        </span>
      </div>
    </li>
    <div class="flex items-center lock-unlock-menu-option" (mouseover)="this.showFullMenu=true">
      <div *ngIf="this.fixedFullMenu" (click)="this.fixedFullMenuHandler(false)" class="flex items-center  cursor-pointer">
        <mat-icon class="pl-1 text-black opacity-60 h-6 w-6">lock_open</mat-icon>
        <span class="text-[12px] text-gray-800 pl-2 overflow-hidden whitespace-nowrap text-ellipsis" [@openClose]="(this.showFullMenu || this.fixedFullMenu) ? 'open' : 'closed'" translate>
          Unlock navigation
        </span>
      </div>
      <div *ngIf="!this.fixedFullMenu" (click)="this.fixedFullMenuHandler(true)" class="flex items-center  cursor-pointer">
        <mat-icon class="pl-1 text-black opacity-60 h-6 w-6">lock</mat-icon>
        <span class="text-[12px] text-gray-800 pl-2 overflow-hidden whitespace-nowrap text-ellipsis" [@openClose]="(this.showFullMenu || this.fixedFullMenu) ? 'open' : 'closed'" translate>
          Lock navigation
        </span>
      </div>
    </div>
  </ul>
</div>
