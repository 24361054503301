import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoginModule } from './modules/login/login.module';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

import { environment } from '../environments/environment';

import { TopNavbarComponent } from './components/top-navbar/top-navbar.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';

import { UserDetailDialogComponent } from './dialogs/user-detail-dialog/user-detail-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';

import { MatIconModule } from '@angular/material/icon';
import { AddUserDialogComponent } from './dialogs/add-user-dialog/add-user-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoadingComponent } from './shared/standalone-components/loading/loading.component';
import { IconButtonModule } from './shared/components/icon-button/icon-button.module';
import { TextButtonModule } from './shared/components/text-button/text-button.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { TitleCasePipe } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";

registerLocaleData(localeEs, "es");

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { ErrorPipe } from './shared/pipes/error.pipe';
import { CustomMessageDialogComponent } from './dialogs/custom-message-dialog/custom-message-dialog.component';
import { CustomButtonModule } from "./shared/components/custom-button/custom-button.module";
import { CustomMaterialModule } from "./shared/custom-material.module";
import { CustomPipesModule } from "./shared/pipes/custom-pipes.module";
import { CustomInputModule } from "./shared/components/custom-input/custom-input.module";
import { NglFormValidatorModule } from 'ngl-form-validator';
import { RolePipe } from './shared/pipes/role.pipe';

const dbConfig: DBConfig = {
  name: 'simskillsStorageDb',
  version: 1,
  objectStoresMeta: [{
    store: 'simskills',
    storeConfig: {keyPath: 'id', autoIncrement: true},
    storeSchema: [
      {name: 'name', keypath: 'name', options: {unique: false}},
      {name: 'email', keypath: 'email', options: {unique: false}},
    ],
  }]
};

@NgModule({
  declarations: [
    AppComponent,
    TopNavbarComponent,
    SideNavComponent,
    ConfirmDialogComponent,
    UserDetailDialogComponent,
    AddUserDialogComponent,
    AppModule.PIPES,
    CustomMessageDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LoginModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    LoadingComponent,
    IconButtonModule,
    TextButtonModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CustomButtonModule,
    CustomMaterialModule,
    CustomPipesModule,
    CustomInputModule,
    NglFormValidatorModule,
  ],
  providers: [
    TitleCasePipe,
    RolePipe,
    {provide: LOCALE_ID, useValue: "en-US"},
    {provide: LOCALE_ID, useValue: "es"}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  static PIPES = [
    ErrorPipe,
  ];
}
