import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userProgress'
})
export class UserProgressPipe implements PipeTransform {

  transform(module: any): number {
    if (!module) {
      return 0;
    }
    const totalAvatar = module.total_avatar || 0;
    const totalPeer = module.total_peers || 0;
    const avatarSimulations = (module.simulations_avatar || 0) > totalAvatar? totalAvatar: (module.simulations_avatar || 0);
    const peerSimulations = (module.simulations_peers || 0) > totalPeer? totalPeer: (module.simulations_peers || 0);
    const currentValue = avatarSimulations+peerSimulations;
    const total = totalAvatar + totalPeer;
    if(!total) {
      return 0;
    }
    const result = (currentValue / total) * 100;
    return Math.round(result);
  }

}
