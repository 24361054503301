import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: [],
})
export class IconButtonComponent {
  @Input() name: string = '';
  @Input() styles: string = 'bg-white';
}
