import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomInputComponent } from "./custom-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CustomMaterialModule } from "../../custom-material.module";
import { NglFormValidatorModule } from "ngl-form-validator";
import { FORM_VALIDATOR_SETTINGS } from "../../../utils/form-validator.settings";

@NgModule({
  declarations: [
    CustomInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    TranslateModule,
    NglFormValidatorModule.forRoot(FORM_VALIDATOR_SETTINGS),
  ],
  exports: [
    CustomInputComponent
  ]
})
export class CustomInputModule {
}
