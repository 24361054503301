import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryType } from '../../utils/custom-types';
import { ModuleEnum } from '../../utils/enums/module.enum';
import { UserGradeEnum } from '../../utils/enums/user-grade.enum';

@Pipe({
  name: 'dictionary'
})
export class DictionaryPipe implements PipeTransform {

  private dictionaryStrategy: any;

  constructor(
    private translateService: TranslateService
  ) {
    this.initDictionaryStrategy();
  }

  transform(key: string, dictionary: DictionaryType): string {
    let value: string;
    if(!this.dictionaryStrategy[dictionary]) {
      return '';
    }
    value = this.dictionaryStrategy[dictionary](key);
    return this.translateService.instant(value);
  }


  private initDictionaryStrategy(): void {
    this.dictionaryStrategy = {
      language: (language: string): string => {
        let value;
        if (language === 'es-ES') {
          value = 'Español';
        } else if (language === 'en-US') {
          value = 'Ingles';
        }
        return value;
      },
      module: (module: string) => {
        let value: string;
        if (module === ModuleEnum.feedback) {
          value = 'Feedback';
        } else if (module === ModuleEnum.conversations) {
          value = 'Difficult Conversations';
        } else if (module === ModuleEnum.motivation) {
          value = 'Motivate From Purpose';
        } else if (module === ModuleEnum.interviews) {
          value = 'Interviews';
        } else if (module === ModuleEnum.health_dialogues) {
          value = 'Valuable Conversation with Patients';
        } else if (module === ModuleEnum.salesAndServices) {
          value = 'Sales & services';
        } else if (module === ModuleEnum.demo) {
          value = 'Demo simulations';
        }
        return value;
      },
      avatarGender: (gender: string) => {
        let value: string;
        if (gender === 'male') {
          value = 'Male';
        } else if (gender === 'female') {
          value = 'Female';
        }
        return value;
      },
      boolean: (boolValue: boolean) => {
        let value: string;
        if (boolValue) {
          value = 'Yes';
        } else {
          value = 'No';
        }
        return value;
      },
      userGrade: (userGrade: UserGradeEnum) => {
        let value: string;
        if (userGrade === UserGradeEnum.beginner) {
          value = 'Beginner';
        } else if (userGrade === UserGradeEnum.medium) {
          value = 'Medium';
        } else if (userGrade === UserGradeEnum.advanced) {
          value = 'Advanced';
        }
        return value;
      }
    }
  }

}
