import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TextButtonComponent } from './text-button.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TextButtonComponent],
  imports: [CommonModule, MatButtonModule, TranslateModule],
  exports: [TextButtonComponent],
})
export class TextButtonModule {}
