<div class="shadow-xl p-4">
  <div *ngIf="this.data.header?.content" class="text-center font-semibold text-[18px] flex justify-between">
    <div [ngClass]="this.data.header?.css" [innerHTML]="this.data.header?.content"></div>
    <button (click)="this.dialogRef.close()" type="button" class="close flex" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div mat-dialog-content>
    <div [ngClass]="this.data.content?.css" [innerHTML]="this.data.content?.content"></div>
  </div>
  <div class="flex justify-end p-4">
    <button class="w-[162px] h-[50px] rounded-[10px] border-solid border-2 border-[#F2B23E] mx-2" [ngClass]="action.css" (click)="this.actionCallback(action)"
            *ngFor="let action of this.data.actions" translate>{{action.content}}</button>
  </div>
</div>
