import { Pipe, PipeTransform } from '@angular/core';
import { SortDirection } from "../../utils/custom-types";


@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(values: any[], property?: string, direction: SortDirection = 'asc'): any[] {
    if(!values) {
      return [];
    }
    return values.sort((a, b) => {
      const isAsc = direction === 'asc';
      if (!property) {
        return this.compare(a, b, isAsc);
      }
      return this.compare(a[property], b[property], isAsc);
    });
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    let auxA = a === undefined ? '' : a;
    let auxB = b === undefined ? '' : b;
    return (auxA < auxB ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
