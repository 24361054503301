import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'error'
})
export class ErrorPipe implements PipeTransform {

  transform(errors: any, errorKey: string): any {
    if (!errors) {
      return;
    }
    let error;
    for (const key in errors) {
      if(key === errorKey) {
        error = errors[key];
        break;
      }
    }
    return error;
  }

}
