import { Component, Inject } from '@angular/core';
import {
  CustomMessageDialogActionModel,
  CustomMessageDialogModel
} from '../../utils/models/custom-message-dialog.model';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'custom-message-dialog',
  templateUrl: './custom-message-dialog.component.html',
  styleUrls: ['./custom-message-dialog.component.css']
})
export class CustomMessageDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CustomMessageDialogComponent>,
    @Inject(DIALOG_DATA) public data: CustomMessageDialogModel
  ) {
  }
  
  protected actionCallback(action: CustomMessageDialogActionModel): void {
    if (action?.callback) {
      action.callback();
    }
    this.dialogRef.close(action.action);
  }
}
