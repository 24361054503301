import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './admin-guards';
import { ManagerGuard } from './manager-guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'recovery-password',
    loadChildren: () => import('./modules/recovery-password/recovery-password.module').then((m) => m.RecoveryPasswordModule),
  },
  {
    path: 'admin-users-table',
    loadChildren: () => import('./modules/admin/admin-users-table/admin-users-table.module').then( (m) => m.AdminUsersTableModule ), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'admin-users-table/:filter',
    loadChildren: () => import('./modules/admin/admin-users-table/admin-users-table.module').then( (m) => m.AdminUsersTableModule ), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'manager-users-table',
    loadChildren: () => import( './modules/admin/admin-users-table/admin-users-table.module' ).then((m) => m.AdminUsersTableModule), canActivate: [ManagerGuard], canLoad: [ManagerGuard],
  },
  {
    path: 'manager-users-table/:filter',
    loadChildren: () => import( './modules/admin/admin-users-table/admin-users-table.module' ).then((m) => m.AdminUsersTableModule), canActivate: [ManagerGuard], canLoad: [ManagerGuard],
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./modules/admin/admin-dashboard/admin-dashboard.module').then( (m) => m.AdminDashboardModule ), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'admin-reports',
    loadChildren: () => import('./modules/admin/admin-reports/admin-reports.module').then( (m) => m.AdminReportsModule ), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'simulation-peers-add',
    loadChildren: () => import('./modules/simulation-peers-add/simulation-peers-add.module').then( (m) => m.SimulationPeersAddModule ), canActivate: [AdminGuard],
  },
  {
    path: 'simulations/:category',
    loadChildren: () => import('./modules/simulations/simulations.module').then( (m) => m.SimulationsModule ), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'simulations/avatar/:module/:id',
    loadChildren: () => import('./modules/simulation-detail/simulation-detail.module').then( (m) => m.SimulationDetailModule ), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'simulations/avatar/add',
    loadChildren: () => import('./modules/simulation-detail/simulation-detail.module').then( (m) => m.SimulationDetailModule ), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'simulations/peer/:module/:id',
    loadChildren: () => import('./modules/simulation-peer-detail/simulation-peer-detail.module').then( (m) => m.SimulationPeerDetailModule ), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'simulations/peer/add',
    loadChildren: () => import('./modules/simulation-peer-detail/simulation-peer-detail.module').then( (m) => m.SimulationPeerDetailModule ), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'ai-simulations',
    loadChildren: () => import('./modules/ai-simulations/ai-simulations.module').then( (m) => m.AiSimulationsModule ), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'ai-simulations/:id',
    loadChildren: () => import( './modules/ai-simulation-detail/ai-simulation-detail.module' ).then((m) => m.AiSimulationDetailModule), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'ai-chat-simulation/:id',
    loadChildren: () => import('./modules/ai-chat-simulation/ai-chat-simulation.module').then( (m) => m.AiChatSimulationModule ), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'manager-dashboard',
    loadChildren: () => import( './modules/manager/manager-dashboard/manager-dashboard.module' ).then((m) => m.ManagerDashboardModule), canActivate: [ManagerGuard], canLoad: [ManagerGuard],
  },
  {
    path: 'companies',
    loadChildren: () => import( './modules/companies/companies.module' ).then((m) => m.CompaniesModule), canActivate: [AdminGuard], canLoad: [AdminGuard],
  },
  {
    path: 'assessments',
    loadChildren: () => import( './modules/assessments/assessments.module' ).then((m) => m.AssessmentsModule), canActivate: [AdminGuard], canLoad: [AdminGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
