import { Component, Input } from '@angular/core';

@Component({
  selector: 'text-button',
  templateUrl: './text-button.component.html',
  styleUrls: [],
})
export class TextButtonComponent {
  @Input() name: string = '';
  @Input() styles: string = 'bg-white';
  @Input() disabled: string = 'false';
}
