import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserInfoService } from 'src/app/services/user-info.service';

export interface DialogData {
  uid: string;
  name?: string;
  email?: string;
  company?: string;
  lastLogin?: string;
  initials?: string;
  initialQuiz?: false;
  selfEfficacyQuiz?: boolean;
  module?: string;
  avatarSimulation?: number;
  p2pSimulation?: number;
  p2pScheduled?: string;
  approved?: boolean;
  displayCompany?: boolean;
  companies?: [];
}

export interface Assessment {
  feedback: {
    pre_training: any;
  };
  general: {
    initial_survey: any;
  };
}

export interface Journey {
  current_module: string;
}
export interface Attempt {
  completed: boolean;
  approved: boolean;
}

export interface UserSimulations {
  attempts: Attempt[];
}

@Component({
  selector: 'app-user-detail-dialog',
  templateUrl: './user-detail-dialog.component.html',
  styleUrls: [],
})
export class UserDetailDialogComponent {

  approved = false;
  declare assessment: Assessment;
  declare journey: Journey;
  declare scheduledSimulations: any[];
  declare userSimulations: any[];
  avatarSimulations = 0;
  p2pSimulations = 0;
  isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogData>,
    private userInfoService: UserInfoService
  ) {}

  ngOnInit() {
    console.log(this.data);

    // this.initialSurvey = this.data.assessments?.general || null;
    // this.preTraining = this.data.assessments?.feedback?.pre_training || null;

    this.avatarSimulations = this.data.feedback?.simulations_avatar || 0;
    this.p2pSimulations = this.data.feedback?.simulations_peers || 0;

    this.isLoading = false;

    // this.getAuthorized();
    // this.getAssessments();
    // this.getJourney();
    // this.getScheduledSimulations();
    // this.setAmountAvatarSimulations();
    // this.setAmountP2PSimulations();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getAuthorized() {
    this.userInfoService.isUserAuthorized(this.data.uid).then((result: any) => {
      if (result) {
        this.approved = result.approved;
      }
    });
  }

  getScheduledSimulations() {
    this.userInfoService
      .getIncommingScheduledSimulations(this.data.uid)
      .then((simulations: any) => {
        if (simulations) {
          let simulationsList: string[] = [];
          simulationsList = simulations.reduce(
            (total: any[], simulation: any) => {
              this.userInfoService
                .getUser(simulation.peerId)
                .then((user: any) => {
                  let newItem = {
                    displayName: user.displayName,
                    date: simulation.p2p_date,
                  };

                  total.push(newItem);
                  return total;
                });
              return total;
            },
            []
          );
          this.scheduledSimulations = simulationsList;
        }
      });
  }

  getAssessments() {
    this.userInfoService
      .getUserAssessments(this.data.uid)
      .then((assessment: any) => {
        if (assessment) {
          this.assessment = assessment;
        }
      });
  }

  setAmountAvatarSimulations() {
    this.userInfoService
      .getAmountAvatarSimulations(this.data.uid)
      .then((avatarSimulations: number) => {
        if (avatarSimulations) {
          this.avatarSimulations = avatarSimulations;
        }
      });
  }

  setAmountP2PSimulations() {
    this.userInfoService
      .getAmountP2PSimulations(this.data.uid)
      .then((p2pSimulations: any) => {
        if (p2pSimulations) {
          this.p2pSimulations = p2pSimulations;
        }
        this.isLoading = false;
      });
  }

  get initialSurvey() { return this.data.assessments?.general?.initial_survey || false; }

  get preTraining() { return this.data.assessments?.feedback?.pre_training || false; }

  changeAuthorized() {
    this.userInfoService.changeAuthorized(this.data.uid).then(() => {
      this.getAuthorized();
    });
  }

  getJourney() {
    if (this.data && this.data.company) {
      let company = '';
      company = this.data.company;
      company = company.toLowerCase();
      this.userInfoService
        .getUserJourney(this.data.company, this.data.uid)
        .then((journey: any) => {
          if (journey) {
            this.journey = journey;
          }
        });
    }
    this.journey = { current_module: 'None' };
  }
}
