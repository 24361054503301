import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snakeCaseToTitle'
})
export class SnakeCaseToTitlePipe implements PipeTransform {
  
  transform(value: string): string {
    if (!value) {
      return '';
    }
    return value.replaceAll('_', ' ');
  }
  
}
