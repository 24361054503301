<div *ngIf="!isLoading" class="flex items-center justify-start rounded-full">
  <div class="grid grid-cols-2 gap-2 p-8 w-full text-gray-800">
    <div class="col-span-2 flex flex-row pb-4">
  <img [src]="'https://ui-avatars.com/api/?name=' + data.initials + '&background=F3B13E&color=fff'"
    class="w-20 h-20 rounded-full">
  <div class=" flex flex-col justify-start pl-7 py-2">
    <h1 class="font-medium text-lg leading-4" *ngIf="data.displayCompany">{{data.company}}</h1>
    <h1 class="font-semibold text-lg leading-4 pb-[2px] whitespace-nowrap overflow-hidden">{{data.name}}</h1>
    <a class="font-normal pb-1 text-base leading-4 focus-visible:outline-none hover:underline break whitespace-nowrap overflow-hidden"
      [href]="'mailto:'+ data.email">{{data.email}}</a>
  </div>
  <div class="w-full flex flex-row-reverse">
    <mat-icon class="w-20 h-20 cursor-pointer hover:opacity-70" (click)="closeDialog()">close</mat-icon>
  </div>
</div>

<div class="col-span-2 grid grid-cols-2 gap-4 border-t-[1px] mx-3 border-gray-500">
  <div class="flex flex-col justify-start items-start my-6 border-r-[1px] border-gray-500">

    <div>
      <div class="pb-2">
          <h1 class="font-semibold text-lg" translate>Current module</h1>
          <p class="font-medium text-base">{{data.modules}}</p>
        </div>
    </div>
    <div class="pb-2">
      <h1 class="font-semibold text-lg" translate>Tasks</h1>
      <div class="flex items-center">
        <mat-icon inline="true" *ngIf="initialSurvey" class="mat-icon text-green-900 notranslate material-icons mat-ligature-font mat-icon-no-color" fontIcon="check_circle"></mat-icon>
        <mat-icon inline="true" *ngIf="!initialSurvey" class="mat-icon text-red-900 notranslate material-icons mat-ligature-font mat-icon-no-color" fontIcon="cancel"></mat-icon>
        <p class="font-medium text-base ml-2" translate>Initial questionnaire</p>
      </div>
      <div class="flex items-center">
        <mat-icon inline="true" *ngIf="preTraining" class="mat-icon text-green-900 notranslate material-icons mat-ligature-font mat-icon-no-color" fontIcon="check_circle"></mat-icon>
        <mat-icon inline="true" *ngIf="!preTraining" class="mat-icon text-red-900 notranslate material-icons mat-ligature-font mat-icon-no-color" fontIcon="cancel"></mat-icon>
        <p class="font-medium text-base ml-2" translate>Self-efficacy questionnaire</p>
      </div>
    </div>
        <div>
          <h1 class="font-semibold text-lg">Journey</h1>
          <p class="font-medium text-base" *ngIf="avatarSimulations === 0" translate>
            <!-- <mat-icon [ngClass]="{ 'text-red-900': !avatarSimulations, 'text-green-900': avatarSimulations }" class="mat-icon notranslate align-middle text-green-900 material-icons mat-ligature-font mat-icon-no-color" fontIcon="cancel"></mat-icon> -->
            No avatar simulations
          </p>
          <p class="font-medium text-base" *ngIf="avatarSimulations === 1" translate>{{avatarSimulations}} avatar simulation</p>
          <p class="font-medium text-base" *ngIf="avatarSimulations > 1" translate>{{avatarSimulations}} avatar simulations</p>
          <p class="font-medium text-base" *ngIf="p2pSimulations === 0" translate>
            <!-- <mat-icon [ngClass]="{ 'text-red-900': !p2pSimulations, 'text-green-900': p2pSimulations }" class="mat-icon notranslate align-middle text-green-900 material-icons mat-ligature-font mat-icon-no-color" fontIcon="cancel"></mat-icon> -->
            No simulations with a partner
          </p>
          <p class="font-medium text-base" *ngIf="p2pSimulations === 1" translate>{{p2pSimulations}} simulation with a partners</p>
          <p class="font-medium text-base" *ngIf="p2pSimulations > 1" translate>{{p2pSimulations}} simulations with partners</p>
        </div>
      </div>
      <div class="flex flex-col justify-start items-start pl-10 py-6">
        <div class="pb-2">
          <h1 class="font-semibold text-lg" translate>Last login</h1>
          <p class="font-medium text-base">{{data.last_login | date: 'dd/MM/yyyy'}}</p>
        </div>
        <div class="pb-2">
          <h1 class="font-semibold text-lg" translate>Programmed Simulation</h1>
          <div *ngIf="data.p2p_date">
            <p class="font-medium text-base">
              {{(data.p2p_date | date: 'dd/MM/yyyy') + (data.peer_name ? ' con ' + data.peer_name : '')}} 
            </p>
          </div>
          <p class="font-medium text-base" *ngIf="!data.p2p_date" translate>Does not have</p>
        </div>
        <!--
<div class="flex flex-row items-center pb-2">
          <h1 class="font-semibold text-lg text-center pr-3">Acciones</h1>
          <mat-icon *ngIf="approved" class="w-20 h-20 cursor-pointer hover:opacity-70"
            (click)="changeAuthorized()">lock_open</mat-icon>
          <mat-icon *ngIf="!approved" class="w-20 h-20 cursor-pointer hover:opacity-70"
            (click)="changeAuthorized()">lock</mat-icon>
        </div>
        -->

      </div>
    </div>
  </div>
</div>

<loading-component *ngIf="isLoading"></loading-component>