<div class="rounded-xl shadow-xl">
  <div mat-dialog-content>
    <p class="text-center">{{ data.message }}</p>
  </div>
  <div mat-dialog-actions class="flex flex-row w-full">
    <text-button styles="bg-red-100 text-gray-800 p-2 rounded-lg hover:bg-red-900 mx-7 my-4 ml-20 w-20" name="Cancel"
      (click)="onNoClick()"></text-button>
    <text-button styles="bg-green-100 text-gray-800 p-2 rounded-lg hover:bg-green-900 ml-36 mx-7 my-4 w-20"
      (click)="onYesClick()" name="Accept"></text-button>
  </div>
</div>