import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: [],
})
export class TopNavbarComponent {
  env;
  user: any = null;
  spanish: boolean = true;
  private path: string = '../../../assets/icon';

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {

    this.env = environment.name;
    this.matIconRegistry
      .addSvgIcon('en', this.setPath(`${this.path}/en.svg`))
      .addSvgIcon('es', this.setPath(`${this.path}/es.svg`));
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      this.user = JSON.parse(userInfo);
    }
  }

  getInitials() {
    return this.user.firstName?.slice(0, 1) + this.user.lastName?.slice(0, 1);
  }

  openConfirmDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: { message: '¿Estás seguro de que quieres cerrar la sesión?' },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.authService.logout();
        await this.router.navigateByUrl('/login');
        window.location.reload();
      }
    });
  }

  navToPlatform() {
    window.open('http://simskills.io/', '_blank');
  }

  changeLanguage() {
    this.spanish = !this.spanish;
    const language: string = this.spanish ? 'es' : 'en';
    this.translate.use(language);
  }
}
