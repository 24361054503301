import { Component, Input } from '@angular/core';

@Component({
  selector: 'custom-button',
  templateUrl: './custom-button.component.html',
})
export class CustomButtonComponent {
  @Input()
  public disabled: boolean;
  @Input()
  public class: string;
  @Input()
  public bgStyle: string;

  constructor() {
    this.bgStyle = 'bg-yellow-900';
    this.class = '';
  }
}
