import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assessment'
})
export class AssessmentPipe implements PipeTransform {

  transform(assessment: string): string {
    let result = '';
    switch (assessment) {
      case 'pre_training': {
        result = 'Pre training (self-efficacy)';
        break;
      }
      case 'post_training': {
        result = 'Post training';
        break;
      }
      case 'post_avatar': {
        result = 'Post avatar (evaluation)';
        break;
      }
      case 'post_p2p': {
        result = 'Post p2p';
        break
      }
      case 'p2p_self_assessment': {
        result = 'P2p self-assessment';
        break
      }
      case 'p2p_peer_evaluation': {
        result = 'P2p peer evaluation';
        break
      }
      default: {
        break;
      }
    }
    return result;
  }

}
