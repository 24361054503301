<!--suppress ALL -->
<mat-form-field *ngIf="this.type === 'text'" [hintLabel]="this.hintLabel" [appearance]="this.appearance" [floatLabel]="this.floatLabel">
  <mat-label class="pl-2" translate>{{this.label}}</mat-label>
  <input #customInput type="text" matInput [formControl]="$any(this.ngControl.control)" [maxlength]="this.maxlength"
         [placeholder]="this.placeholder | translate" >
  <span *ngIf="this.textPrefix" matTextPrefix>{{this.textPrefix}}&nbsp;</span>
  <span *ngIf="this.textSuffix" matTextSuffix>{{this.textSuffix}}</span>
  <mat-hint *ngIf="this.maxlength" align="end">{{this.ngControl.control?.value?.length}}/{{this.maxlength}}</mat-hint>
  <mat-error nglFormValidator [customErrors]="this.customErrors" [formControl]="$any(this.ngControl.control)" [inputReference]="customInput" translate>
  </mat-error>
</mat-form-field>


<mat-form-field *ngIf="this.type === 'number'" [hintLabel]="this.hintLabel" [appearance]="this.appearance">
  <mat-label class="pl-2" translate>{{this.label}}</mat-label>
  <input type="number" matInput [formControl]="$any(this.ngControl.control)" [maxlength]="this.maxlength" [placeholder]="this.placeholder"
         #customInput>
  <span *ngIf="this.textPrefix" matTextPrefix>{{this.textPrefix}}&nbsp;</span>
  <span *ngIf="this.textSuffix" matTextSuffix>{{this.textSuffix}}</span>
  <mat-hint *ngIf="this.maxlength" align="end">{{this.ngControl.control?.value?.length}}/{{this.maxlength}}</mat-hint>
  <mat-error nglFormValidator [formControl]="$any(this.ngControl.control)" [inputReference]="customInput">
  </mat-error>
</mat-form-field>

<mat-form-field *ngIf="this.type === 'password'" [hintLabel]="this.hintLabel" [appearance]="this.appearance">
  <mat-label class="pl-2" translate>{{this.label}}</mat-label>
  <input [type]="!this.isVisiblePassword? 'password': 'text'" matInput [formControl]="$any(this.ngControl.control)" [maxlength]="this.maxlength"
         [placeholder]="this.placeholder" #customInput>
  <span *ngIf="this.textPrefix" matTextPrefix>{{this.textPrefix}}&nbsp;</span>
  <button matTextSuffix mat-icon-button (click)="this.isVisiblePassword = !this.isVisiblePassword " aria-label="Example icon button with a vertical three dot icon">
    <mat-icon *ngIf="!this.isVisiblePassword">visibility_off</mat-icon>
    <mat-icon *ngIf="this.isVisiblePassword">visibility</mat-icon>
  </button>
  <span *ngIf="this.textSuffix" matTextSuffix>{{this.textSuffix}}</span>
  <mat-hint *ngIf="this.maxlength" align="end">{{this.ngControl.control?.value?.length}}/{{this.maxlength}}</mat-hint>
  <mat-error nglFormValidator [formControl]="$any(this.ngControl.control)" [inputReference]="customInput">
  </mat-error>
</mat-form-field>



