import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetters'
})
export class FirstLettersPipe implements PipeTransform {

  transform(value: string, separator = ' '): string[] {
    const firstLetters: string[] = [];
    if (!value) {
      return firstLetters;
    }
    const words = value.split(separator)
    for (const word of words) {
      if (!word) {
        continue;
      }
      firstLetters.push(word[0]);
    }
    return firstLetters;
  }

}
