import { Injectable } from '@angular/core';
import { CanActivate, Router, Route, CanLoad } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ManagerGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    let res = await this.authService.isConnected();
    if (!res) this.router.navigateByUrl('/login');
    return res;
  }

  async canLoad(route: Route): Promise<boolean> {
    let user: any = await this.authService.getUser();
    let userInfo = localStorage.getItem('userInfo')!;
    let parsedUser = JSON.parse(userInfo);
    return (
      user && (parsedUser.role === 'admin' || parsedUser.role === 'manager')
    );
  }
}
