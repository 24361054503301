import { Injectable } from '@angular/core';
import { GoogleAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: any;
  public isLoggedUser: boolean;

  constructor(
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        setTimeout(() => {
          this.isLoggedUser = true;
        }, 1000);
        localStorage.setItem('user', JSON.stringify(user));
        if (!this.user) {
          await this.updateUserFromDB(user);
        }
      } else {
        this.isLoggedUser = false;
        localStorage.removeItem('user');
      }
    });
  }

  // Get user from DB and save data to storage
  public updateUserFromDB(user: any) {
    // Get user from DB to store locally
    this.firebaseService
      .getDocument(`/users/${user.uid}`)
      .then(async (u: any) => {
        if (!u) {
          return; // first time login, the user does not exist in the DB
        }
        this.firebaseService
          .updateDocument('/users', user.uid, {
            last_login: new Date().getTime(),
          })
          .then(async (_) => {
            u.uid = user.uid;
            this.user = u;
            localStorage.setItem('userInfo', JSON.stringify(this.user));
          });
      });
  }

  // Sign in email and password
  public emailAuth(data: any) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(data.email, data.password).then(
        async (res) => {
          console.log(res.user);
          if (!res.user) {
            return;
          }
          let user;
          const approvedUser = await this.isUserAuthorized(res.user);

          if (approvedUser) {
            user = {
              ...res.user.providerData[0],
              uid: res.user.uid,
              approved: approvedUser.approved,
              emailVerified: res.user.emailVerified,
            };
          } else {
            user = {
              ...res.user.providerData[0],
              uid: res.user.uid,
              approved: false,
              emailVerified: res.user.emailVerified,
            };
          }

          resolve(user);
        },
        (err) => reject(err)
      );
    });
  }

  // Sign in with Google
  public googleAuth() {
    return this.afAuth
      .signInWithPopup(new GoogleAuthProvider())
      .then(async (res) => {
        console.log(res.user);
        if (!res.user) {
          return null;
        }
        let user;
        const approvedUser = await this.isUserAuthorized(res.user);
        if (approvedUser) {
          user = {
            ...res.user.providerData[0],
            uid: res.user.uid,
            approved: approvedUser.approved,
          };
        }
        // No value in 'authorized_users' = the user logs-in with goole for the first time
        else {
          // Autorize the user
          this.firebaseService.updateDocument(
            '/authorized_users',
            res.user.uid,
            true
          );
          user = {
            ...res.user.providerData[0],
            uid: res.user.uid,
            approved: true,
          };
        }

        return user;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public register(value: any) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  public async sendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        console.log('email send');
      });
  }

  public async forgotPassword(passwordResetEmail: string) {
    try {
      await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
      return { success: true };
    } catch (error: any) {
      let message;
      if (error.code === 'auth/user-not-found') {
        message = 'The email address was not found.';
      }
      if (error.code === 'auth/invalid-email') {
        message = 'Invalid email';
      }
      return { success: false, message };
    }
  }

  public isUserAuthorized(user: any) {
    return new Promise<any>((resolve, reject) => {
      this.firebaseService.getDocument('/authorized_users/' + user.uid).then(
        (res: any) => {
          resolve(res);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  public async logout() {
    localStorage.removeItem('user');
    localStorage.clear();
    await this.afAuth.signOut();
  }

  public async getUser() {
    const user = localStorage.getItem('user');
    return user;
  }

  async isConnected() {
    const user = localStorage.getItem('user');
    return !!user;
  }
}
