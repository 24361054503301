import { Injectable } from '@angular/core';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
  PathReference,
  QueryFn,
} from '@angular/fire/compat/database';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  [x: string]: any;
  module = 'feedback';
  bookingListRef: AngularFireList<any> | undefined;
  bookingRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  subscribeDocument(url: string, limit: number = 0) {
    let query;
    if (limit)
      query = (ref: { limitToLast: (arg0: number) => any }) =>
        ref.limitToLast(limit);

    return this.db
      .list(url, query)
      .snapshotChanges()
      .pipe(
        map((changes: any) =>
          changes.map((c: { payload: { key: any; val: () => any } }) => ({
            key: c.payload.key,
            ...c.payload.val(),
          }))
        )
      );
  }

  getDocument(url: string, callbackObj: any = null):any {
    return new Promise((resolve) => {
      this.db.object(url).valueChanges().subscribe((res) => {
          if (callbackObj) {
            callbackObj.callback.call(callbackObj.self, res);
          }
          resolve(res);
        });
    });
  }

  getDocumentByIndex(url: string, index: number) {
    return new Promise((resolve) => {
      this.db.list(url, (ref) => ref.orderByChild('index').equalTo(index)).valueChanges().subscribe((res) => {
          if (res) resolve(res[0]);
          else resolve(false);
        });
    });
  }

  getDocumentByProperty(url: string, property: string, value: string): Promise<any> {
    return new Promise((resolve) => {
      this.db.list(url, (ref) => ref.orderByChild(property).equalTo(value)).valueChanges().subscribe((res) => {
          if (res) resolve(res[0]);
          else resolve(false);
        });
    });
  }

  public getDocumentsListOnce(url: string, data: any = null): Promise<any[]> {
    let query: QueryFn | undefined;
    if (data) {
      if (data.orderByChild) {
        if (data.startAt && data.endAt)
          query = (ref) => ref.orderByChild(data.orderByChild).startAt(data.startAt).endAt(data.endAt);
        else if (data.startAt)
          query = (ref) => ref.orderByChild(data.orderByChild).startAt(data.startAt);
        else if (data.endAt)
          query = (ref) => ref.orderByChild(data.orderByChild).endAt(data.endAt);
        else if (data.equalTo != null)
          query = (ref) => ref.orderByChild(data.orderByChild).equalTo(data.equalTo);
        else
          query = (ref) => ref.orderByChild(data.orderByChild);
      }
      if (data.limitToLast) {
        query = (ref) => ref.limitToLast(data.limitToLast);
      }
    }

    return new Promise((resolve) => {
      let sub = this.db
        .list(url, query)
        .snapshotChanges()
        .pipe(
          map((changes: any) =>
            changes.map((c: { payload: { key: any; val: () => any } }) => ({
              id: c.payload.key,
              ...c.payload.val(),
            }))
          )
        )
        .subscribe((res) => {
          resolve(res);
        });

      setTimeout(() => {
        sub.unsubscribe();
      }, 10000);
    });
  }

  async addDocument(url: string, data: any) {
    this.clean(data);
    const res = await this.db.list(environment.dbUrl + url).push(data);
    console.log(`Data was added to ${environment.dbUrl + url}`);
    return res;
  }

  updateDocument(url: string, id: string, data: any): Promise<any> {
    if (url == '/users') data.updated_at = moment().format();

    return new Promise((resolve, reject) => {
      try {
        let newId: string | null;
        if (!id) {
          newId = this.db.list(environment.dbUrl + url).push({}).key;
        }

        this.db
          .object(environment.dbUrl + url + '/' + id)
          .update(data)
          .then(() => {
            resolve(newId);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  async setAssessmentResult(url: string, data: any) {
    const res = await this.db.object(environment.dbUrl + url).set(data);
    console.log(`Data was added to ${environment.dbUrl + url}`);
  }

  async addAssessmentResult(url: string, data: any, set = false) {
    if (set) return this.setAssessmentResult(url, data);
    else await this.db.list(environment.dbUrl + url).push(data);
    console.log(`Data was added to ${environment.dbUrl + url}`);
  }

  async addDocumentQuestion(url: PathReference, data: any) {
    this.clean(data);
    const res = await this.db.list(url).push(data);
    console.log(`Data was added to ${environment.dbUrl + url}`);
  }

  async deleteDocument(url: string, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.db
          .object(environment.dbUrl + url + '/' + id)
          .remove()
          .then(() => {
            resolve(true);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  private clean(obj: { [x: string]: any }) {
    for (var propName in obj) {
      if (propName == 'password') {
        delete obj[propName];
        continue;
      }
      if (obj[propName] === null || obj[propName] === undefined) {
        continue;
      }
      if (typeof obj[propName] == 'string') {
        obj[propName] = obj[propName].trim();
      }
      if (
        propName != 'department' &&
        propName != 'phone' &&
        propName != 'mobile' &&
        typeof obj[propName] == 'string' &&
        obj[propName] != '' &&
        !isNaN(obj[propName])
      ) {
        obj[propName] = Number(obj[propName]);
      }
      if (obj[propName] == 'object') {
        this.clean(obj[propName]);
      }

      if (typeof obj[propName] == 'object') this.clean(obj[propName]);
    }
  }

  public generateId() {
    return this.db.createPushId();
  }
}
