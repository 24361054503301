import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slice'
})
export class SlicePipe implements PipeTransform {

  transform(values: any[], start?: number, end?: number): any[] {
    if(!values) {
      return [];
    }
    return values.slice(start, end);
  }

}
