import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { CustomMessageDialogComponent } from "../dialogs/custom-message-dialog/custom-message-dialog.component";
import { CustomMessageDialogModel } from "../utils/models/custom-message-dialog.model";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private translateService: TranslateService,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
  ) {
  }

  public snackOpen(message: string, action = 'OK'): void {
    const translatedMessage = this.translateService.instant(message);
    this.matSnackBar.open(translatedMessage, action, {duration: 5000, horizontalPosition: 'right'});
  }


  public openInfoDialog({content, header = {}, width = '500px', actions = []}: CustomMessageDialogModel) {
    header.css = 'bg-[#F2B23E] text-[#FFFFFF]';
    const data: CustomMessageDialogModel = {
      content,
      header,
      actions
    }
    const dialogRef = this.matDialog.open(CustomMessageDialogComponent, {
      width,
      data,
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }


  public confirmDialog(message: string, {
    title= '',
    callbackOk = null,
    okTextButton = 'OK',
    // @ts-ignore
    callbackCancel = null,
    cancelTextButton = 'Cancel',
    width = '500px'
  }): void {
    const data: CustomMessageDialogModel = {
      header: {
        content: title,
        css: 'text-[22px] font-medium text-[#616161] p-2',
      },
      content: {
        content: this.translateService.instant(message),
        css: 'text-[20px] font-medium text-[#616161] p-2',
      },
      actions: [
        {
          action: 'CANCEL',
          content: this.translateService.instant(cancelTextButton),
          css: 'bg-[#FFFFFF]'
        }, {
          action: 'OK',
          content: this.translateService.instant(okTextButton),
          css: 'bg-[#F2B23E]'
        },
      ]
    }
    const dialogRef = this.matDialog.open(CustomMessageDialogComponent, {
      id: 'confirmDialog',
      width,
      data,
      panelClass: ['mat-dialog-border-radius']
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'CANCEL' || !result) {
        if (!callbackCancel) {
          return;
        }
        return callbackCancel();
      } else if (result === 'OK') {
        if (!callbackOk) {
          return;
        }
        return callbackOk();
      }
    });
  }
}
